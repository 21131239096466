import React from 'react';
import equal from 'fast-deep-equal'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Notificationpopup from './../../components/Notificationpopup';
import { apiPut } from './../../auth/APIRequests';
import { ENDPOINTS } from './../../auth/EndPoints';
import { dateTimeNow } from './../../auth/DateConverions';


export class UpdateReviews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSnack: false,
            snackColor: 'bg-primary',
            snackMsg: '',
            clientName: '',
            clientText: '',
            reviewId: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
       this.setState({
           clientName:this.props.reviewDetail.reviewClientName,
           clientText:this.props.reviewDetail.reviewClientText,
           reviewId:this.props.reviewDetail.id,
       })
    }
    componentDidUpdate(prevProps) {
        if(!equal(this.props.reviewDetail.id, prevProps.reviewDetail.id)) 
        {
            this.setState({
                clientName:this.props.reviewDetail.reviewClientName,
                clientText:this.props.reviewDetail.reviewClientText,
                reviewId:this.props.reviewDetail.id,
            });
        }
      } 
      
    handleSubmit(event) {   
        const body = {
          id:this.state.reviewId,
          reviewClientName: this.state.clientName,
          reviewClientText: this.state.clientText,
          reviewClientStar: 4,
          reviewDate: dateTimeNow(),
        }
        apiPut(
            ENDPOINTS.UpdateReview,
            body,
            (res) => {
                console.log(res)
                this.setState({
                    showSnack:true,
                    snackMsg:"Successfully updated",
                    snackColor:"bg-success",
                });
                this.props.reLoadData();
                this.props.cloaseModal();
            },
            (error) => {
                this.setState({
                    showSnack:true,
                    snackMsg:"there is an error",
                    snackColor:"bg-danger",
                });
                this.props.reLoadData()
              console.log(error);
            }
          );      
          event.preventDefault();
     console.log(body);
    };
    render() {
        return <>
            <Modal
                show={this.props.showModalUpdate}
                backdrop="static"
            >
                <Modal.Body closeButton>
                    <Form onSubmit={this.handleSubmit}>

                        <Form.Group className="mb-3"   >
                            <Form.Label>Client Name </Form.Label>
                            <Form.Control required type="text" value={this.state.clientName} onChange={(e)=> this.setState({clientName:e.target.value})} placeholder="Client Name" />
                        </Form.Group>
                        <Form.Group className="mb-3"   >
                            <Form.Label>Client Review</Form.Label>
                            <Form.Control required as="textarea" value={this.state.clientText} onChange={(e)=> this.setState({clientText:e.target.value})} placeholder="Client Review" />
                        </Form.Group>
                        <div className="text-right">
                            <Button variant="info" type="submit" onClick={() => this.props.cloaseModal()}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit">
                                Update Review
                            </Button>
                        </div>

                    </Form>
                </Modal.Body>
            </Modal>
            <Notificationpopup
                closeSnackPro={() => this.setState({ showSnack: false })}
                showSnkPro={this.state.showSnack}
                bgColorPro={this.state.snackColor}
                snackMsgPro={this.state.snackMsg}
            />
        </>
    }
}

export default UpdateReviews