import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Notificationpopup from './../../components/Notificationpopup';
import { apiPost } from './../../auth/APIRequests';
import { ENDPOINTS } from './../../auth/EndPoints';
import { dateTimeNow } from './../../auth/DateConverions';

const CreateReviews = (props) => {
    const [showSnack, setSnack ] = useState(false);
    const [snackColor, setSnackColor ] = useState("bg-primary");
    const [snackMsg, setSnackMsg ] = useState("");
    const clientNameEl = React.useRef(null);
    const clientReviewEl = React.useRef(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        const body = {
          reviewClientName: clientNameEl.current.value,
          reviewClientText: clientReviewEl.current.value,
          reviewClientStar: 4,
          reviewDate: dateTimeNow(),
        }
       
        apiPost(
          ENDPOINTS.AddReview,
          body,
          (res) => {
            setSnack(true);
            setSnackColor("bg-success");
            setSnackMsg("Add Reviews Successfully!");
            props.cloaseModal();
            props.reLoadData();
          },
          (error) => {
            setSnack(true);
            setSnackColor("bg-danger");
            setSnackMsg("Not Added Reviews")
            console.log(error);
            props.cloaseModal();
          }
        );
      };
    return (
        <>
            <Modal
                show={props.showModal}
                backdrop="static"
            >
                <Modal.Body closeButton>
                    <Form onSubmit={handleSubmit}>

                        <Form.Group className="mb-3"   >
                            <Form.Label>Client Name</Form.Label>
                            <Form.Control required type="text" ref={clientNameEl} placeholder="Client Name" />
                        </Form.Group>

                        <Form.Group className="mb-3"   >
                            <Form.Label>Client Review</Form.Label>
                            <Form.Control required as="textarea" ref={clientReviewEl} placeholder="Client Review" />
                        </Form.Group>

                        

                        <div className="text-right">
                            <Button variant="info" type="submit" onClick={()=> props.cloaseModal()}>
                             Close
                            </Button>
                            <Button variant="primary" type="submit">
                                Add Review
                            </Button>
                        </div>

                    </Form>
                </Modal.Body>
            </Modal>
            <Notificationpopup 
      closeSnackPro={()=> setSnack(false)}
       showSnkPro={showSnack}
       bgColorPro={snackColor}
       snackMsgPro={snackMsg}
       />
        </>
    )
}

export default CreateReviews
