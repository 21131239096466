
// live
export const BASE_URL = "http://test.gologonow.tech";
export const BASE_URLS = "http://test.gologonow.tech"


// test
// export const BASE_URL = "http://localhost:4000";
// export const BASE_URLS = "http://localhost:4000"
export const ENDPOINTS = {
    CreateUser: `${BASE_URL}/admin/user/register`, 
    UserLogin: `${BASE_URL}/admin/user/login`, 
    BaseUrl: `${BASE_URLS}`, 

    // reviews
    AddReview: `${BASE_URL}/admin/review/add`, 
    AllReviews: `${BASE_URL}/admin/review/get`, 
    SingleReview: `${BASE_URL}/admin/review/get/`, 
    UpdateReview: `${BASE_URL}/admin/review/update`, 
    DeleteReview: `${BASE_URL}/admin/review/delete/`, 

    // reviews
    AddClass: `${BASE_URL}/admin/ourclasses/add`, 
    AllClasses: `${BASE_URL}/admin/allclasses/get`, 
    SingleClasses: `${BASE_URL}/admin/ourclasses/get/`, 
    UpdateClasses: `${BASE_URL}/admin/ourclasses/update`, 
    DeleteClass: `${BASE_URL}/admin/ourclasses/delete/`, 

     // Tracks
    AddTracks: `${BASE_URL}/admin/track/add`, 
    AllTracks: `${BASE_URL}/admin/track/get`, 
    SingleTracks: `${BASE_URL}/admin/track/get/`, 
    UpdateTracks: `${BASE_URL}/admin/track/update`, 
    DeleteTracks: `${BASE_URL}/admin/track/delete/`, 

     // EventVides
     AddStream: `${BASE_URL}/admin/stream/add`, 
     AllStream: `${BASE_URL}/admin/stream/get`, 
     SingleStream: `${BASE_URL}/admin/stream/get/`, 
     UpdateStream: `${BASE_URL}/admin/stream/update`, 

       // Event Vides
       AddEventVideos: `${BASE_URL}/admin/event-video/add`, 
       AllEventVideos: `${BASE_URL}/admin/event-video/get`, 
       SingleEventVideos: `${BASE_URL}/admin/event-video/get/`, 
       UpdateEventVideos: `${BASE_URL}/admin/event-video/update`, 
       DeleteEventVideos: `${BASE_URL}/admin/event-video/delete/`, 


       // EventPhoto
       AddEventPhoto: `${BASE_URL}/admin/event-photo/add`, 
       AllEventPhoto: `${BASE_URL}/admin/event-photo/get`, 
       SingleEventPhoto: `${BASE_URL}/admin/event-photo/get/`, 
       UpdateEventPhoto: `${BASE_URL}/admin/event-photo/update`, 
       DeleteEventPhoto: `${BASE_URL}/admin/event-photo/delete/`, 

       // HealthAndBeauty
       AddHealthAndBeauty: `${BASE_URL}/admin/health-and-beauty/add`, 
       AllHealthAndBeauty: `${BASE_URL}/admin/health-and-beauty/get`, 
       SingleHealthAndBeauty: `${BASE_URL}/admin/health-and-beauty/get/`, 
       UpdateHealthAndBeauty: `${BASE_URL}/admin/health-and-beauty/update`, 
       DeleteHealthAndBeauty: `${BASE_URL}/admin/health-and-beauty/delete/`, 

       //appointment
       AllAppointment: `${BASE_URL}/admin/appointment/get`, 
       SingleAppointment: `${BASE_URL}/admin/appointment/get/`, 
       UpdateAppointment: `${BASE_URL}/admin/appointment/update`, 
       DeleteAppointment: `${BASE_URL}/admin/appointment/delete/`, 
};