import React from "react";
import equal from "fast-deep-equal";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Notificationpopup from "./../../components/Notificationpopup";
import { apiPut } from "./../../auth/APIRequests";
import { ENDPOINTS } from "./../../auth/EndPoints";
import { CheckAudioExtension, ConvertDefaultHtml } from "./../../auth/DateConverions";

export class UpdatesAppointmentss extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSnack: false,
      snackColor: "bg-primary",
      snackMsg: "",
      Id: 0,
      AppointmentTime: "",
      DateOfBirth: "",
      Email: "",
      FirstName: "",
      Gender: "",
      LastName: "",
      PhoneNumber: "",
      Services: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this.setState({
      Id: this.props.ItemDetail.id,
      AppointmentTime: this.props.ItemDetail.appointmentTime,
      DateOfBirth: this.props.ItemDetail.dateOfBirth,
      Email: this.props.ItemDetail.email,
      FirstName: this.props.ItemDetail.firstName,
      Gender: this.props.ItemDetail.gender,
      LastName: this.props.ItemDetail.lastName,
      PhoneNumber: this.props.ItemDetail.phoneNumber,
      Services: this.props.ItemDetail.services,
    });
  }
  componentDidUpdate(prevProps) {
    if (!equal(this.props.ItemDetail.id, prevProps.ItemDetail.id)) {
      this.setState({
        Id: this.props.ItemDetail.id,
        AppointmentTime: this.props.ItemDetail.eventName,
        DateOfBirth: this.props.ItemDetail.dateOfBirth,
        Email: this.props.ItemDetail.email,
        FirstName: this.props.ItemDetail.firstName,
        Gender: this.props.ItemDetail.gender,
        LastName: this.props.ItemDetail.lastName,
        PhoneNumber: this.props.ItemDetail.phoneNumber,
        Services: this.props.ItemDetail.services,
      });
    }
  }


  checkFilesAcceptAudio = (e) => {
    if (e.target.files.length > 0) {
      this.setState({ isAudioUpload: true });
    }
    const fileStatus = CheckAudioExtension(e.target.files[0]);
    if (!fileStatus) {
      document.getElementById("audio-input").value = null;
      this.setState({
        showSnack: true,
        snackMsg: "there is an error",
        snackColor: "bg-danger",
      });
    }
  };

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    apiPut(
      ENDPOINTS.UpdateAppointment,
      data,
      (res) => {
        console.log(res);
        this.setState({
          showSnack: true,
          snackMsg: "Successfully updated",
          snackColor: "bg-success",
        });
        this.props.reLoadData();
        this.props.cloaseModal();
        // eslint-disable-next-line no-restricted-globals
        // location.reload();
      },
      (error) => {
        this.setState({
          showSnack: true,
          snackMsg: "there is an error",
          snackColor: "bg-danger",
        });
        this.props.reLoadData();
        console.log(error);
      }
    );
  }
  render() {
    return (
      <>
        <Modal show={this.props.showModalUpdate} backdrop="static">
          <Modal.Body>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="First Name"
                  className="appointment-inputs"
                  name="firstName"
                  value={this.state.FirstName}
                  onChange={(e) => this.setState({ FirstName: e.target.value })}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  className="appointment-inputs"
                  value={this.state.LastName}
                  onChange={(e) => this.setState({ LastName: e.target.value })}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  required
                  type="number"
                  name="phoneNumber"
                  placeholder="Formate +14155552671"
                  className="appointment-inputs"
                  value={this.state.PhoneNumber}
                  onChange={(e) =>
                    this.setState({ PhoneNumber: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="appointment-inputs"
                  value={this.state.Email}
                  onChange={(e) => this.setState({ Email: e.target.value })}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Date Of Birth</Form.Label>
                <Form.Control
                  required
                  type="date"
                  placeholder="Date Of birth"
                  name="dateOfBirth"
                  className="appointment-inputs"
                  value={this.state.DateOfBirth}
                  onChange={(e) =>
                    this.setState({ DateOfBirth: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Gender</Form.Label>
                <select
                  className="form-control appointment-inputs"
                  value={this.state.Gender}
                  name="gender"
                  onChange={(e) => this.setState({ Gender: e.target.value })}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Appointment Time</Form.Label>
                <Form.Control
                  required
                  type="datetime-local"
                  placeholder="Appointment Time"
                  className="appointment-inputs"
                  name="appointmentTime"
                  value={ConvertDefaultHtml(this.state.AppointmentTime)}
                  onChange={(e) =>
                    this.setState({ AppointmentTime: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Services</Form.Label>
                <select
                  name="services"
                  className="form-control appointment-inputs"
                  value={this.state.Services}
                  onChange={(e) => this.setState({ Services: e.target.value })}
                >
                  <option value="skin care">skin care</option>
                  <option value="yoga">yoga</option>
                </select>
                <Form.Control
                  name="id"
                  value={this.state.Id}
                  type="hidden"
                />
              </Form.Group>
              <div className="text-right">
                <Button variant="info" onClick={() => this.props.cloaseModal()}>
                  Close
                </Button>
                <Button variant="primary" type="submit">
                  Update Track
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        <Notificationpopup
          closeSnackPro={() => this.setState({ showSnack: false })}
          showSnkPro={this.state.showSnack}
          bgColorPro={this.state.snackColor}
          snackMsgPro={this.state.snackMsg}
        />
      </>
    );
  }
}

export default UpdatesAppointmentss;
