import React from "react";
import equal from "fast-deep-equal";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Notificationpopup from "./../../components/Notificationpopup";
import { apiPut } from "./../../auth/APIRequests";
import { ENDPOINTS } from "./../../auth/EndPoints";
  import { CheckImageExtension } from "./../../auth/DateConverions";

export class UpdatePhotoEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSnack: false,
      snackColor: "bg-primary",
      snackMsg: "",
      Id: 0,
      EventName: "",
      EventImgSrc: "",
      isVideoUpload: false,
      updateImg: "false",
      EventImgPath:""
    };
    this.handleSubmit = this.handleSubmit.bind(this);

  }
  componentDidMount() {
    this.setState({
      Id: this.props.ItemDetail.id,
      EventName: this.props.ItemDetail.eventName,
      EventImgSrc: this.props.ItemDetail.eventImgPath,
    });
  }
  componentDidUpdate(prevProps) {
    if (!equal(this.props.ItemDetail.id, prevProps.ItemDetail.id)) {
      this.setState({
        Id: this.props.ItemDetail.id,
        EventName: this.props.ItemDetail.eventName,
        EventImgSrc: this.props.ItemDetail.eventImgPath,
      });
    }
  }

  checkFilesAcceptImg = (e) => {
    if (e.target.files.length > 0) {
      this.setState({ updateImg: "true" });
    }
    const fileStatus = CheckImageExtension(e.target.files[0]);
    if (!fileStatus) {
      document.getElementById("img-input").value = null;
      this.setState({
        showSnack: true,
        snackMsg: "Accept Only .svg , .png, .jpg file",
        snackColor: "bg-danger",
      });
    }
  };

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    apiPut(
      ENDPOINTS.UpdateEventPhoto,
      data,
      (res) => {
        this.setState({
          showSnack: true,
          snackMsg: "Successfully updated",
          snackColor: "bg-success",
        });
        this.props.reLoadData();
        this.props.cloaseModal();
          // eslint-disable-next-line no-restricted-globals
      },
      (error) => {
        this.setState({
          showSnack: true,
          snackMsg: "there is an error",
          snackColor: "bg-danger",
        });
        this.props.reLoadData();
        console.log(error);
      }
    );
  }
  render() {
    return (
      <>
        <Modal show={this.props.showModalUpdate} backdrop="static">
          <Modal.Body>
            <Form onSubmit={this.handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Video Event Name</Form.Label>
              <Form.Control
                required
                type="text"
                name="eventname"
                placeholder="Video Event Name"
                value={this.state.EventName}
                onChange={(e) => this.setState({ EventName: e.target.value })}

              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Chose Video  ( mp4 files )</Form.Label>
              <Form.Control
                type="file"
                name="imgfile"
                id="img-input"
                onChange={(e) => this.checkFilesAcceptImg(e)}
                placeholder="Client Review"
              />
                <img src={ENDPOINTS.BaseUrl+''+  this.props.ItemDetail.eventImgPath} alt="file" className="show-img-file mt-2"/>

                 <Form.Control
                  type="hidden"
                  value={this.props.ItemDetail.id}
                  name="id"
                />
                 <Form.Control
                  type="hidden"
                   value={this.props.ItemDetail.eventImgPath}
                  name="eventimgpath"
                />
                 <Form.Control
                  type="hidden"
                  value={this.state.updateImg}
                  name="updateImg"
                />
            </Form.Group>
            <div className="text-right">
              <Button
                variant="info"
                onClick={() => this.props.cloaseModal()}
              >
                Close
              </Button>
              <Button variant="primary" type="submit">
                Update
              </Button>
            </div>
          </Form>
          </Modal.Body>
        </Modal>
        <Notificationpopup
          closeSnackPro={() => this.setState({ showSnack: false })}
          showSnkPro={this.state.showSnack}
          bgColorPro={this.state.snackColor}
          snackMsgPro={this.state.snackMsg}
        />
      </>
    );
  }
}

export default UpdatePhotoEvents;
