// reactstrap components
import React, { useState, useEffect } from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";
import AgoraRTC from "agora-rtc-sdk";
import { apiGetAuth } from "./../auth/APIRequests";
import { ENDPOINTS } from "./../auth/EndPoints";
import Header from "components/Headers/Header.js";
import UpdateStream from "components/Forms/UpdateStream";
var rtc = {
  client: null,
  joined: false,
  published: false,
  localStream: null,
  remoteStreams: [],
  params: {},
};

// var option = {
//   appID: "231aa9d2969b479995206f53da7e1a98",
//   channel: "testss",
//   uid: null,
//   token: "0065897e940c4a143adb154947fb94a5e59IAAQkp3izaXTTZ0AD3F+v99YPWnZi8LR1oIKeuTbMovplATTz5cAAAAAIgDEGoyZMQN4YQQAAQAxA3hhAgAxA3hhAwAxA3hhBAAxA3hh"
// };

const Index = (props) => {
  const [allItems, setAllItems] = useState([]);
  const [singleItem, setSingleItem] = useState({});
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [option, setOption] = useState({
    appID: "",
    channel: "",
    uid: null,
    token:
      "",
  });

  useEffect(() => {
    getAll();
  }, []);

   function getAll() {
    apiGetAuth(
      ENDPOINTS.AllStream,
      (res) => {
        setAllItems(res.result);
        if(res.result.length> 0){
          setOption({
            appID: res.result[0]['appId'],
            channel:res.result[0]['channelName'],
            uid: null,
            token:res.result[0]['token'],
          });
        }
  
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const leaveEventHost = () => {
    rtc.client.unpublish(rtc.localStream, function (err) {
      console.log("publish failed");
      console.error(err);
  })
  rtc.client.leave(function (ev) {
      console.log(ev)
  })
}
  const getDetail = (item) => {
    console.log(item, 'details')
    setSingleItem(item);
    setUpdateModal(true);

  };

  const joinChannel = () => {
    rtc.client = AgoraRTC.createClient({ mode: "live", codec: "h264" });
    // Initialize the client
    rtc.client.init(option.appID, function () {
        console.log("init success");

        // Join a channel
        rtc.client.join(option.token ?
            option.token : null,
            option.channel, option.uid ? +option.uid : null, function (uid) {
                console.log("join channel: " + option.channel + " success, uid: " + uid);
                rtc.params.uid = uid;
                    rtc.client.setClientRole("host");
                    // Create a local stream
                    rtc.localStream = AgoraRTC.createStream({
                        streamID: rtc.params.uid,
                        audio: true,
                        video: true,
                        screen: false,
                    })

                    // Initialize the local stream
                    rtc.localStream.init(function () {
                        console.log("init local stream success");
                        rtc.localStream.play("local_stream");
                        rtc.client.publish(rtc.localStream, function (err) {
                            console.log("publish failed");
                            console.error(err);
                        })
                    }, function (err) {
                        console.error("init local stream failed ", err);
                    });

                    rtc.client.on("connection-state-change", function (evt) {
                        console.log("audience", evt)
                    })
                }, function (err) {
                console.error("client join failed", err)
            })

    }, (err) => {
        console.error(err);
    });
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <UpdateStream
        ItemDetail={singleItem}
        showModalUpdate={showUpdateModal}
        reLoadData={() => getAll()}
        cloaseModal={() => setUpdateModal(false)}
      />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Live Streaming</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={joinChannel}
                      size="sm"
                    >
                      Start Streaming
                    </Button>
                    <Button
                      color="danger"
                      href="#pablo"
                      onClick={leaveEventHost}
                      size="sm"
                    >
                      Stop Streaming
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="position-relative">
                <div
             id="local_stream" className="local_stream live-streaming-host"
            />

                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container className="mt-5" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Live Streaming Setting</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>App Id</th>
                    <th>Channel Name</th>
                    <th>Token</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                {/* <tbody>
                  {allItems.length > 0 &&
                    allItems.map((item, index) => (
                      <tr key={index}>
                        <td>{item.appId}</td>
                        <td>{item.channelName}</td>
                        <td className="text-stop-long">{item.token}</td>
                        <td>
                          <button
                            className="edit-btn"
                            onClick={() => getDetail(item)}
                          >
                            Edit
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody> */}
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Index;
