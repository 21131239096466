import moment from 'moment';


export const ConvertTimeStampInToDate = (date) => {
	return moment.utc(date).format('DD-MM-YYYY');
};

export const ConvertDateinToTime = (date) => {
	return moment(date).format('hh:mm A');
};
export const ConvertDefaultHtml = (date) => {
	return moment(date).format('YYYY-MM-DDTHH:mm');
};
export const ConvertYearMonthDate = (date) => {
  return moment(date).format('YYYY-MM-DD');
};
export const DateWithMOnthName = (date) => {
 return moment(date).format('DD MMMM YYYY');
};
export const dateTimeNow = () => {
  return moment().format();
 };
export const ChatTime = (date) => {
  return moment(date).format('LT');
};
export const ConvertInTime = (date) => {
 const times = date.split(':');
     return `${times[0]}:${times[1]}`;
};
export const CheckImageExtension = (filename) => {
 const imgType = ['jpg','png','svg'] ;
 var  fileType =  filename['name'].split('.').pop();
 const acceptFiles=  imgType.includes(fileType)
return acceptFiles;
};
export const CheckAudioExtension = (filename) => {
 const imgType = ['mp3'] ;
 var  fileType =  filename['name'].split('.').pop();
 const acceptFiles=  imgType.includes(fileType)
return acceptFiles;
};
export const CheckVideoExtension = (filename) => {
  const imgType = ['mp4'] ;
  var  fileType =  filename['name'].split('.').pop();
  const acceptFiles=  imgType.includes(fileType)
 return acceptFiles;
 };