import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Notificationpopup from './../../components/Notificationpopup';
import { apiPost } from './../../auth/APIRequests';
import { ENDPOINTS } from './../../auth/EndPoints';

const CreateClasses = (props) => {
    const [showSnack, setSnack] = useState(false);
    const [snackColor, setSnackColor] = useState("bg-primary");
    const [snackMsg, setSnackMsg] = useState("");




    const handleSubmit = (event) => {

        event.preventDefault();
        const data = new FormData(event.target);
        apiPost(
            ENDPOINTS.AddClass,
            data,
            (res) => {
                setSnack(true);
                setSnackColor("bg-success");
                setSnackMsg("Add Reviews Successfully!");
                props.cloaseModal();
                props.reLoadData();
            },
            (error) => {
                setSnack(true);
                setSnackColor("bg-danger");
                setSnackMsg("Not Added Reviews")
                console.log(error);
                props.cloaseModal();
            }
        );
    };


    return (
        <>
            <Modal
                show={props.showModal}
                backdrop="static"
            >
                <Modal.Body closeButton>
                    <Form onSubmit={handleSubmit}>

                        <Form.Group className="mb-3"   >
                            <Form.Label>Class Title</Form.Label>
                            <Form.Control required type="text" name="className" placeholder="Class Title" />
                        </Form.Group>
                        <Form.Group className="mb-3"   >
                            <Form.Label>Select Specific</Form.Label>
                            <select required name="classType" className="form-control" id="cars">
                                <option value="all">All</option>
                                <option value="male">male</option>
                                <option value="female">Female</option>
                            </select>

                        </Form.Group>

                        <Form.Group className="mb-3"   >
                            <Form.Label>Start Day</Form.Label>
                            <select name="dayStart" required className="form-control" id="cars">
                                <option value="monday">Monday</option>
                                <option value="tuesday">Tuesday</option>
                                <option value="wednesday">Wednesday</option>
                                <option value="thursday">Thursday</option>
                                <option value="friday">Friday</option>
                            </select>

                        </Form.Group>

                        <Form.Group className="mb-3"   >
                            <Form.Label>End Day</Form.Label>
                            <select required name="dayEnd" className="form-control" id="cars">
                                <option value="monday">Monday</option>
                                <option value="tuesday">Tuesday</option>
                                <option value="wednesday">Wednesday</option>
                                <option value="thursday">Thursday</option>
                                <option value="friday">Friday</option>
                            </select>
                        </Form.Group>

                        <Form.Group className="mb-3"   >
                            <Form.Label>Start Time</Form.Label>
                            <Form.Control required type="time" name="timeStart" placeholder="Client Review" />
                        </Form.Group>

                        <Form.Group className="mb-3"   >
                            <Form.Label>End Time</Form.Label>
                            <Form.Control required type="time" name="timeEnd" placeholder="Client Review" />
                        </Form.Group>

                        <Form.Group className="mb-3"   >
                            <Form.Label>Chose Image</Form.Label>
                            <Form.Control required type="file" name="file" placeholder="Client Review" />
                        </Form.Group>
                        <div className="text-right">
                            <Button variant="info" type="submit" onClick={() => props.cloaseModal()}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit">
                                Add Class
                            </Button>
                        </div>

                    </Form>
                </Modal.Body>
            </Modal>
            <Notificationpopup
                closeSnackPro={() => setSnack(false)}
                showSnkPro={showSnack}
                bgColorPro={snackColor}
                snackMsgPro={snackMsg}
            />
        </>
    )
}

export default CreateClasses
