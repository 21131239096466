import React,{useState, useEffect} from "react";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Notificationpopup from './../../components/Notificationpopup';
import { apiPost } from './../../auth/APIRequests';
import { ENDPOINTS } from './../../auth/EndPoints';

const Login = () => {
  const [showSnack, setSnack ] = useState(false);
  const [snackColor, setSnackColor ] = useState("bg-primary");
  const [snackMsg, setSnackMsg ] = useState("");

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if(token){
   window.location.href = '/admin/index';
    }

  },[]);

  const emailEl = React.useRef(null);
  const passwordEl = React.useRef(null);

  const handleSubmit = (e) => {
     e.preventDefault();
    const body = {
      email: emailEl.current.value,
      password: passwordEl.current.value,
    }
    apiPost(
      ENDPOINTS.UserLogin,
      body,
      (res) => {
        setSnack(true);
        setSnackColor("bg-success");
        setSnackMsg("Login Successfully!");
         localStorage.setItem('accessToken', res.accessToken);
         localStorage.setItem('userId', res.userId);
         localStorage.setItem('userEmail', res.userEmail);
         window.location.href = '/admin/index';
      },
      (error) => {
        setSnack(true);
        setSnackColor("bg-danger");
        setSnackMsg("User name and password wrong")
        console.log(error);
      }
    );
   
  };

  return (
    <>
      <Col lg="5" md="7">
        <div className="bg-white sign-up-form">
          <div className="text-center text-muted mb-4">
            <small>sign in with credentials</small>
          </div>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3"   >
              <Form.Label>Email address</Form.Label>
              <Form.Control required type="email" ref={emailEl} placeholder="Email" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control required type="password" ref={passwordEl} placeholder="Password" />
            </Form.Group>
            <div className="text-center">
              <Button variant="primary" type="submit">
                Log In
              </Button>
            </div>
          </Form>
        </div>
        <Notificationpopup 
      closeSnackPro={()=> setSnack(false)}
       showSnkPro={showSnack}
       bgColorPro={snackColor}
       snackMsgPro={snackMsg}
       />
      </Col>
    </>
  );
};

export default Login;
