import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  Table,
  Container,
  Col,
  Row,
  Media
} from "reactstrap";
import Header from "components/Headers/Header.js";
import CreateClasses from "components/Forms/CreateClasses";
import UpdateClasses from "components/Forms/UpdateClasses";
import DeleteClass from "components/Forms/DeleteClass";
import Loader from "components/Loader";
import { apiGetAuth } from './../../auth/APIRequests';
import { ENDPOINTS } from './../../auth/EndPoints';


const OurClasses = () => {
  const [showCreateModal, setCreateModal] = useState(false);
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [deleteClasses, setDeleteClasses] = useState({});
  const [singleClassess, setSingleClasses] = useState({});
  const [allClasses, setAllClasses] = useState([]);
  const [loadUpdateModal, setLoadUpdateModal] = useState(false);

  React.useEffect(() => {
    getAll();
  }, []);

  const getClassDetail = (itemId) => {
    setIsLoading(true);
    apiGetAuth(
      ENDPOINTS.SingleClasses + itemId,
      (res) => {
        console.log(res.classes);
        setSingleClasses(res.classes)
        setUpdateModal(true);
        setLoadUpdateModal(true);
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  function getAll() {
    apiGetAuth(
      ENDPOINTS.AllClasses,
      (res) => {
        setAllClasses(res.clasess);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  

  const deleteReviewEvent = (item) => {
    setDeleteModal(true);
    setDeleteClasses(item);
  }

  return (
    <>
      <Header />
      {isLoading && (
        <Loader />
      )}

      <CreateClasses showModal={showCreateModal} cloaseModal={() => setCreateModal(false)} reLoadData={() => getAll()} />
      {loadUpdateModal && (
        <UpdateClasses showModalUpdate={showUpdateModal} reLoadData={() => getAll()} cloaseModal={() => setUpdateModal(false)} itemDetail={singleClassess} />
      )}
      <DeleteClass showDeleteModalPro={showDeleteModal} reLoadData={() => getAll()} closeDeleteModalPro={() => setDeleteModal(false)} deletePro={deleteClasses} />

      <Container className="mt--7" fluid>
        <Row>
          <Col md={12} className="text-right mb-3">
            <button onClick={() => setCreateModal(true)} className="add-btn">Add</button>
          </Col>
        </Row>
        {/* Table */}
        <React.Fragment>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Classes tables</h3>
              </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Class Image</th>
                    <th>class title</th>
                    <th>class Start day</th>
                    <th>class End Day</th>
                    <th>class Start time</th>
                    <th>class end time</th>
                    <th>class Specific</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {allClasses.length && allClasses.map((item,index) =>
                    <tr key={index}>
                      <td><Media className="align-items-center">
                        <a
                          className="avatar rounded-circle mr-3"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <img
                            alt={item.className}
                            src= {ENDPOINTS.BaseUrl+''+item.imgPath}
                            className="avatar-custom"
                          />
                        </a>
                      </Media></td>
                      <td>{item.className}</td>
                      <td>{item.dayStart}</td>
                      <td>{item.dayEnd}</td>
                      <td>{item.timeStart}</td>
                      <td>{item.timeEnd}</td>
                      <td>{item.classType}</td>
                      <td><button className="edit-btn" onClick={() => getClassDetail(item.id)}>Edit</button></td>
                      <td><button className="delete-btn" onClick={() => deleteReviewEvent(item)}>Delete</button></td>
                    </tr>
                  )}
                  </tbody>

                </Table>
            </Card>
          </div>
        </Row>
        </ React.Fragment>
      </Container>


    </>
  )
}

export default OurClasses
