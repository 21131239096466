import React, { useState } from 'react';
import {
    Card,
    CardHeader,
    Table,
    Container,
    Col,
    Row,
    Media
} from "reactstrap";
import Header from "components/Headers/Header.js";
import CreateEventPhoto from "components/Forms/CreateEventPhoto";
import DeleteAppointments from "components/Forms/DeleteAppointments";
import UpdatesAppointmentss from "components/Forms/UpdatesAppointmentss";
import Loader from "components/Loader";
import { apiGetAuth } from './../../auth/APIRequests';
import { ENDPOINTS } from './../../auth/EndPoints';


const Appointments = () => {
    const [showCreateModal, setCreateModal] = useState(false);
    const [showUpdateModal, setUpdateModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showDeleteModal, setDeleteModal] = useState(false);
    const [deleteItem, setDeleteItem] = useState({});
    const [singleItem, setSingleItem] = useState({});
    const [all, setAll] = useState([]);


    React.useEffect(() => {
        getAll();
    }, []);


    const getDetail = (itemId) => {
        setIsLoading(true);
        apiGetAuth(
            ENDPOINTS.SingleAppointment + itemId,
            (res) => {
                setSingleItem(res.result)
                setUpdateModal(true);
                setIsLoading(false);
            },
            (error) => {
                console.log(error);
            }
        );
    }
    function getAll() {
        apiGetAuth(
            ENDPOINTS.AllAppointment,
            (res) => {
                console.log(res.Resul,'all')

                setAll(res.Result);
            },
            (error) => {
                console.log(error);
            }
        );
    }
    const deleteEvent = (item) => {
        setDeleteModal(true);
        setDeleteItem(item);
    }

    return (
        <>
            <Header />
            {isLoading && (
                <Loader />
            )}
            <CreateEventPhoto showModal={showCreateModal} cloaseModal={() => setCreateModal(false)} reLoadData={() => getAll()} />
            <UpdatesAppointmentss showModalUpdate={showUpdateModal} reLoadData={() => getAll()} cloaseModal={() => setUpdateModal(false)} ItemDetail={singleItem} />
            <DeleteAppointments showDeleteModalPro={showDeleteModal} reLoadData={() => getAll()} closeDeleteModalPro={() => setDeleteModal(false)} deletePro={deleteItem} />
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">Appointments tables</h3>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Phone Number</th>
                                        <th>Email</th>
                                        <th>Date Of Birth</th>
                                        <th>Gender</th>
                                        <th>Appointment Time</th>
                                        <th>Services</th>
                                        <th>Edit</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {all.length && all.map((item, index) =>
                                        <tr key={index}>
                                            <td>{item.firstName}</td>
                                            <td>{item.lastName}</td>
                                            <td>{item.phoneNumber}</td>
                                            <td>{item.email}</td>
                                            <td>{item.dateOfBirth}</td>
                                            <td>{item.gender}</td>
                                            <td>{item.appointmentTime}</td>
                                            <td>{item.services}</td>
                                            <td><button className="edit-btn" onClick={() => getDetail(item.id)}>Edit</button></td>
                                            <td><button className="delete-btn" onClick={() => deleteEvent(item)}>Delete</button></td>
                                        </tr>
                                    )}

                                </tbody>

                            </Table>

                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    )
}

export default Appointments
