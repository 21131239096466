
// reactstrap components
import React,{useState} from "react";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Notificationpopup from './../../components/Notificationpopup';
import { apiPost } from './../../auth/APIRequests';
import { ENDPOINTS } from './../../auth/EndPoints';


const Register = () => {
  const [showSnack, setSnack ] = useState(false);
  const [snackColor, setSnackColor ] = useState("bg-primary");
  const [snackMsg, setSnackMsg ] = useState("");
  const firstNameEl = React.useRef(null);
  const lastNameEl = React.useRef(null);
  const emailEl = React.useRef(null);
  const passwordEl = React.useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      firstName: firstNameEl.current.value,
      lastName: lastNameEl.current.value,
      email: emailEl.current.value,
      password: passwordEl.current.value,
    }
   
    apiPost(
      ENDPOINTS.CreateUser,
      body,
      (res) => {
        setSnack(true);
        setSnackColor("bg-success");
        setSnackMsg("User Registered Successfully!");
        window.location.href = '/auth/login';
      },
      (error) => {
        setSnack(true);
        setSnackColor("bg-danger");
        // eslint-disable-next-line no-lone-blocks
        { error.response.status === 422 ? setSnackMsg(error.response.data.message) : setSnackMsg("User Not Registere")}
        console.log(error);
      }
    );
  };

  return (
    <>
      <Col lg="6" md="8">
        <div className="bg-white sign-up-form">
          <div className="text-center text-muted mb-4">
            <small>sign up with credentials</small>
          </div>
          <Form onSubmit={handleSubmit}>

            <Form.Group className="mb-3"   >
              <Form.Label>Fisrt Name</Form.Label>
              <Form.Control required type="text" ref={firstNameEl} placeholder="Fisrt Name" />
            </Form.Group>

            <Form.Group className="mb-3"   >
              <Form.Label>Last Name</Form.Label>
              <Form.Control required type="text" ref={lastNameEl} placeholder="Last Name" />
            </Form.Group>

            <Form.Group className="mb-3"   >
              <Form.Label>Email address</Form.Label>
              <Form.Control required type="email" ref={emailEl} placeholder="Email" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control required type="password" ref={passwordEl} placeholder="Password" />
            </Form.Group>
            <div className="text-center">
              <Button variant="primary" type="submit">
                Sign Up
              </Button>
            </div>

          </Form>
        </div>

      </Col>
      <Notificationpopup 
      closeSnackPro={()=> setSnack(false)}
       showSnkPro={showSnack}
       bgColorPro={snackColor}
       snackMsgPro={snackMsg}
       />
    </>
  );
};

export default Register;
