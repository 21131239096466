
import Index from "views/Index.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Reviews from "views/examples/Reviews.js";
import OurClasses from "views/examples/OurClasses.js";
import Tracks from "views/examples/Tracks.js"; 
import EventsVideos from "views/examples/EventsVideos.js"; 
import EventsPhotos from "views/examples/EventsPhotos.js"; 
import HealthAndBeauty from "views/examples/HealthAndBeauty.js"; 
import Appointments from "views/examples/Appointments.js"; 


var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/event-videos",
    name: "Events Videos",
    icon: "ni ni-button-play",
    component: EventsVideos,
    layout: "/admin",
  },
  {
    path: "/event-photos",
    name: "Events Photos",
    icon: "ni ni-album-2",
    component: EventsPhotos,
    layout: "/admin",
  },
  {
    path: "/appointments",
    name: "Appointments",
    icon: "ni ni-album-2",
    component: Appointments,
    layout: "/admin",
  },
  {
    path: "/health-and-beauty",
    name: "Health And Beauty",
    icon: "ni ni-glasses-2",
    component: HealthAndBeauty,
    layout: "/admin",
  },
  {
    path: "/reviews",
    name: "Reviews",
    icon: "ni ni-single-copy-04",
    component: Reviews,
    layout: "/admin",
  },
  {
    path: "/our-classes",
    name: "Our Classes",
    icon: "ni ni-user-run",
    component: OurClasses,
    layout: "/admin",
  },
  {
    path: "/tracks",
    name: "Tracks",
    icon: "ni ni-user-run",
    component: Tracks,
    layout: "/admin",
  },
  {
    path: "/login",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/register",
    component: Register,
    layout: "/auth",
  },
];
export default routes;
