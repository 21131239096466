import React from 'react';
import Toast from 'react-bootstrap/Toast'

const Notificationpopup = (props) => {
  return (
    <>
      <Toast
        onClose={() => props.closeSnackPro()}
        className={`toast-updated ${props.bgColorPro}`}
        show={props.showSnkPro}
        delay={3000}
        autohide>
        <Toast.Header />
        <Toast.Body className="text-white">{props.snackMsgPro}</Toast.Body>
      </Toast>
    </>
  )
}

export default Notificationpopup
