import React from "react";
import equal from "fast-deep-equal";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Notificationpopup from "./../../components/Notificationpopup";
import { apiPut } from "./../../auth/APIRequests";
import { ENDPOINTS } from "./../../auth/EndPoints";
  import { CheckVideoExtension } from "./../../auth/DateConverions";

export class UpdateVideoEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSnack: false,
      snackColor: "bg-primary",
      snackMsg: "",
      Id: 0,
      EventVideoName: "",
      EventVideoSrc: "",
      isVideoUpload: false,
      UpdateVideo: "false",
    };
    this.handleSubmit = this.handleSubmit.bind(this);

  }
  componentDidMount() {
    this.setState({
      Id: this.props.ItemDetail.id,
      EventVideoName: this.props.ItemDetail.eventName,
      EventVideoSrc: this.props.ItemDetail.videoPath,
    });
  }
  componentDidUpdate(prevProps) {
    if (!equal(this.props.ItemDetail.id, prevProps.ItemDetail.id)) {
      this.setState({
        Id: this.props.ItemDetail.id,
        EventVideoName: this.props.ItemDetail.eventName,
        EventVideoSrc: this.props.ItemDetail.videoPath,
      });
    }
  }

  checkFilesAcceptVideo = (e) => {
    if (e.target.files.length > 0) {
      this.setState({ UpdateVideo: "true" });
    }
    const fileStatus = CheckVideoExtension(e.target.files[0]);
    if (!fileStatus) {
      document.getElementById("video-input").value = null;
      this.setState({
        showSnack: true,
        snackMsg: "Accept Only mp4 file",
        snackColor: "bg-danger",
      });
    }
  };

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    apiPut(
      ENDPOINTS.UpdateEventVideos,
      data,
      (res) => {
        this.setState({
          showSnack: true,
          snackMsg: "Successfully updated",
          snackColor: "bg-success",
        });
        this.props.reLoadData();
        this.props.cloaseModal();
          // eslint-disable-next-line no-restricted-globals
           location.reload();
      },
      (error) => {
        this.setState({
          showSnack: true,
          snackMsg: "there is an error",
          snackColor: "bg-danger",
        });
        this.props.reLoadData();
        console.log(error);
      }
    );
  }
  render() {
    return (
      <>
        <Modal show={this.props.showModalUpdate} backdrop="static">
          <Modal.Body>
            <Form onSubmit={this.handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Video Event Name</Form.Label>
              <Form.Control
                required
                type="text"
                name="eventname"
                placeholder="Video Event Name"
                value={this.state.EventVideoName}
                onChange={(e) => this.setState({ EventVideoName: e.target.value })}

              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Chose Video  ( mp4 files )</Form.Label>
              <Form.Control
                type="file"
                name="videofile"
                id="video-input"
                onChange={(e) => this.checkFilesAcceptVideo(e)}
                placeholder="Client Review"
              />
                 <Form.Control
                  type="hidden"
                  value={this.props.ItemDetail.id}
                  name="id"
                />
                 <Form.Control
                  type="hidden"
                  value={this.state.UpdateVideo}
                  name="updatevideo"
                />
                 <Form.Control
                  type="hidden"
                  value={this.props.ItemDetail.videoPath}
                  name="videopath"
                />
            </Form.Group>
            <div className="text-right">
              <Button
                variant="info"
                onClick={() => this.props.cloaseModal()}
              >
                Close
              </Button>
              <Button variant="primary" type="submit">
                Update
              </Button>
            </div>
          </Form>
          </Modal.Body>
        </Modal>
        <Notificationpopup
          closeSnackPro={() => this.setState({ showSnack: false })}
          showSnkPro={this.state.showSnack}
          bgColorPro={this.state.snackColor}
          snackMsgPro={this.state.snackMsg}
        />
      </>
    );
  }
}

export default UpdateVideoEvents;
