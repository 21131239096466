import React from 'react';
import equal from 'fast-deep-equal'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Notificationpopup from './../../components/Notificationpopup';
import { apiPost } from './../../auth/APIRequests';
import { ENDPOINTS } from './../../auth/EndPoints';


export class UpdateClasses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSnack: false,
            snackColor: 'bg-primary',
            snackMsg: '',
            classTitle: '',
            selectSpecific: '',
            dayStart: '',
            timeEnd: '',
            startTime: '',
            endTime: '',
            filePath: '',
            uploadNew: false,
            classId:0
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        console.log(this.props.itemDetail, 'classes abc');
       this.setState({
        classTitle:this.props.itemDetail.className,
        selectSpecific:this.props.itemDetail.classType,
        dayStart:this.props.itemDetail.dayStart,
        dayEnd:this.props.itemDetail.dayEnd,
        timeStart:this.props.itemDetail.timeStart,
        timeEnd:this.props.itemDetail.timeEnd,
        classId:this.props.itemDetail.id,
        filePath:this.props.itemDetail.imgPath
       })
    }
    componentDidUpdate(prevProps) {
        if(!equal(this.props.itemDetail.id, prevProps.itemDetail.id)) 
        {
            this.setState({
                classTitle:this.props.itemDetail.className,
                selectSpecific:this.props.itemDetail.classType,
                dayStar:this.props.itemDetail.dayStar,
                dayEnd:this.props.itemDetail.dayEnd,
                timeStart:this.props.itemDetail.timeStart,
                timeEnd:this.props.itemDetail.timeEnd,
                classId:this.props.itemDetail.id,
                filePath:this.props.itemDetail.imgPath
            });
        }
      } 
      
      FileUploaded = (event) => { 
        if(event.target.files.length > 0 ){
            this.setState({uploadNew:true});
        }

     } 
    handleSubmit(event) {   
        event.preventDefault();
        const data = new FormData(event.target);
           console.log(data);
        apiPost(
            ENDPOINTS.UpdateClasses,
            data,
            (res) => {
                console.log(res)
                this.setState({
                    showSnack:true,
                    snackMsg:"Successfully updated",
                    snackColor:"bg-success",
                });
                this.props.reLoadData();
                this.props.cloaseModal();
            },
            (error) => {
                this.setState({
                    showSnack:true,
                    snackMsg:"there is an error",
                    snackColor:"bg-danger",
                });
                this.props.reLoadData()
              console.log(error);
            }
          );      
        //   window.location.reload();
          console.log(data);
    };
    render() {
        return <>
            <Modal
                show={this.props.showModalUpdate}
                backdrop="static"
            >
          <Modal.Body closeButton>
                    <Form  onSubmit={this.handleSubmit}>

                        <Form.Group className="mb-3"   >
                            <Form.Label>Class Title</Form.Label>
                            <Form.Control required type="text" name="classTitle" value={this.state.classTitle} onChange={(e)=> this.setState({classTitle:e.target.value}) } placeholder="Class Title" />
                        </Form.Group>
                        <Form.Group className="mb-3"   >
                            <Form.Label>Select Specific</Form.Label>
                            <select required name="selectSpecific" value={this.state.selectSpecific} onChange={(e)=> this.setState({selectSpecific:e.target.value})}  className="form-control" id="cars">
                                <option value="all">All</option>
                                <option value="male">male</option>
                                <option value="female">Female</option>
                            </select>

                        </Form.Group>

                        <Form.Group className="mb-3"   >
                            <Form.Label>Start Day</Form.Label>
                            <select name="dayStart"  value={this.state.dayStart} onChange={(e)=> this.setState({dayStart:e.target.value})}  required className="form-control" id="cars">
                                <option value="monday">Monday</option>
                                <option value="tuesday">Tuesday</option>
                                <option value="wednesday">Wednesday</option>
                                <option value="thursday">Thursday</option>
                                <option value="friday">Friday</option>
                            </select>

                        </Form.Group>

                        <Form.Group className="mb-3"   >
                            <Form.Label>End Day</Form.Label>
                            <select required name="dayEnd" value={this.state.dayEnd} onChange={(e)=> this.setState({dayEnd:e.target.value})}  className="form-control" id="cars">
                                <option value="monday">Monday</option>
                                <option value="tuesday">Tuesday</option>
                                <option value="wednesday">Wednesday</option>
                                <option value="thursday">Thursday</option>
                                <option value="friday">Friday</option>
                            </select>
                        </Form.Group>

                        <Form.Group className="mb-3"   >
                            <Form.Label>Start Time</Form.Label>
                            <Form.Control required type="time" value={this.state.timeStart} onChange={(e)=> this.setState({timeStart:e.target.value})}  name="timeStart" placeholder="Client Review" />
                        </Form.Group>

                        <Form.Group className="mb-3"   >
                            <Form.Label>End Time</Form.Label>
                            <Form.Control required type="time"  value={this.state.timeEnd} onChange={(e)=> this.setState({timeEnd:e.target.value})}  name="timeEnd" placeholder="Client Review" />
                        </Form.Group>

                        <Form.Group className="mb-3"   >
                            <Form.Label>Chose Image</Form.Label>
                            <Form.Control type="file" onChange={(e)=> this.FileUploaded(e)}  name="file" placeholder="Client Review" />
                        </Form.Group>
                        <Form.Group className="mb-3"   >
                        <Form.Label>Old Image</Form.Label>
                            <img src={ENDPOINTS.BaseUrl+''+ this.props.itemDetail.imgPath} alt="file" className="show-img-file"/>
                            <Form.Control required type="hidden" value={this.state.uploadNew} name="uploadNew" placeholder="Client Review" />
                            <Form.Control required type="hidden" value={this.state.classId} name="id" placeholder="Client Review" />
                            <Form.Control required type="hidden" value={this.state.filePath} name="imgPath" placeholder="Client Review" />
                        </Form.Group>
                        
                        <div className="text-right">
                            <Button variant="info" type="submit" onClick={() => this.props.cloaseModal()}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit">
                                Update Class
                            </Button>
                        </div>

                    </Form>
                </Modal.Body>
            </Modal>
            <Notificationpopup
                closeSnackPro={() => this.setState({ showSnack: false })}
                showSnkPro={this.state.showSnack}
                bgColorPro={this.state.snackColor}
                snackMsgPro={this.state.snackMsg}
            />
        </>
    }
}

export default UpdateClasses