import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  Table,
  Container,
  Col,
  Row,
} from "reactstrap";
import Header from "components/Headers/Header.js";
import CreateTrack from "components/Forms/CreateTrack";
import UpdateTracks from "components/Forms/UpdateTracks";
import DeleteTrack from "components/Forms/DeleteTrack";
import Loader from "components/Loader";
import { apiGetAuth } from './../../auth/APIRequests';
import { ENDPOINTS } from './../../auth/EndPoints';


const Tracks = () => {
  const [showCreateModal, setCreateModal] = useState(false);
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [singleItem, setSingleItem] = useState({});

  const [all, setAll] = useState([]);
  const [loadUpdateModal, setLoadUpdateModal] = useState(false);

  React.useEffect(() => {
    getAll();
  },[]);

  const playePouseAudio = (e,id) =>{
    var audios = document.querySelectorAll('.audio');
    if (audios.length > 0){
      for (var i=0; i < audios.length; i++){
        if (e.target.id !== audios[i].id) {
          audios[i].pause();
        }
      }
    }    
  }
  const getDetail = (itemId) => {
    setIsLoading(true);
    apiGetAuth(
      ENDPOINTS.SingleTracks + itemId,
      (res) => {
        // console.log(res.result)
        setSingleItem(res.result)
        setUpdateModal(true);
        setLoadUpdateModal(true);
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  function getAll() {
    apiGetAuth(
      ENDPOINTS.AllTracks,
      (res) => {
        console.log(res.Result);
         setAll(res.Result);
       
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const deleteEvent = (item) => {
    setDeleteModal(true);
    setDeleteItem(item);
  }

  return (
    <>
      <Header />
      {isLoading && (
        <Loader />
      )}

      <CreateTrack showModal={showCreateModal} cloaseModal={() => setCreateModal(false)} reLoadData={() => getAll()} />
      {loadUpdateModal && (
        <UpdateTracks showModalUpdate={showUpdateModal} reLoadData={() => getAll()} cloaseModal={() => setUpdateModal(false)} ItemDetail={singleItem} />
      )}

      <DeleteTrack showDeleteModalPro={showDeleteModal} reLoadData={() => getAll()} closeDeleteModalPro={() => setDeleteModal(false)} deletePro={deleteItem} />

      <Container className="mt--7" fluid>
        <Row>
          <Col md={12} className="text-right mb-3">
            <button onClick={() => setCreateModal(true)} className="add-btn">Add</button>
          </Col>
        </Row>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Tracks tables</h3>
              </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Name</th>
                    <th>Track</th>
                    <th>Price</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {all.length && all.map((item,index) =>
                    <tr key={index}>
                      <td>{item.trackName}</td>
                      <td> 
                          <audio className="control-audios audio" id={item.id} onPlay={(e)=> playePouseAudio(e,item.id)} controls="controls" controlsList="nodownload">
                        <source src={ENDPOINTS.BaseUrl+''+item.trackSource} type="audio/mp3"></source>
                      </audio></td>
                      <td>${item.trackPrice}</td>
                      <td><button className="edit-btn" onClick={() => getDetail(item.id)}>Edit</button></td>
                      <td><button className="delete-btn" onClick={() => deleteEvent(item)}>Delete</button></td>
                    </tr>
                  )}

                </tbody>

               </Table>

            </Card>
          </div>
        </Row>
      </Container>

    </>
  )
}

export default Tracks
