import React from 'react';
import Spinner from 'react-bootstrap/Spinner'

const Loader = () => {
    return (
        <div className="spinner-box">
              <Spinner animation="border" variant="primary" />
        </div>
    )
}

export default Loader
