import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Notificationpopup from "./../../components/Notificationpopup";
import { apiPost } from "./../../auth/APIRequests";
import { ENDPOINTS } from "./../../auth/EndPoints";
import { CheckVideoExtension } from "./../../auth/DateConverions";
const CreateVideoEvents = (props) => {
  const [showSnack, setSnack] = useState(false);
  const [snackColor, setSnackColor] = useState("bg-primary");
  const [snackMsg, setSnackMsg] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    apiPost(
      ENDPOINTS.AddEventVideos,
      data,
      (res) => {
        setSnack(true);
        setSnackColor("bg-success");
        setSnackMsg("Add Reviews Successfully!");
        props.cloaseModal();
        props.reLoadData();
      },
      (error) => {
        setSnack(true);
        setSnackColor("bg-danger");
        setSnackMsg("Not Added Reviews");
        console.log(error);
        props.cloaseModal();
      }
    );
  };

  const checkFilesAcceptVideo = (e) => {
    const fileStatus = CheckVideoExtension(e.target.files[0]);
    if (!fileStatus) {
      document.getElementById("video-input").value = null;
      setSnack(true);
      setSnackColor("bg-danger");
      setSnackMsg("Only Accept mp4  files");
    }
  };

  return (
    <>
      <Modal show={props.showModal} backdrop="static">
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Video Event Name</Form.Label>
              <Form.Control
                required
                type="text"
                name="eventname"
                placeholder="Video Event Name"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Chose Video</Form.Label>
              <Form.Control
                required
                type="file"
                name="videofile"
                id="video-input"
                onChange={(e) => checkFilesAcceptVideo(e)}
                placeholder="Client Review"
              />
            </Form.Group>
            <div className="text-right">
              <Button
                variant="info"
                type="submit"
                onClick={() => props.cloaseModal()}
              >
                Close
              </Button>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Notificationpopup
        closeSnackPro={() => setSnack(false)}
        showSnkPro={showSnack}
        bgColorPro={snackColor}
        snackMsgPro={snackMsg}
      />
    </>
  );
};

export default CreateVideoEvents;
