import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  Table,
  Container,
  Col,
  Row,
} from "reactstrap";
import Header from "components/Headers/Header.js";
import CreateReviews from "components/Forms/CreateReviews";
import UpdateReviews from "components/Forms/UpdateReviews";
import DeleteReview from "components/Forms/DeleteReview";
import Loader from "components/Loader";
import { apiGetAuth } from './../../auth/APIRequests';
import { ENDPOINTS } from './../../auth/EndPoints';


const Reviews = () => {
  const [showCreateModal, setCreateModal] = useState(false);
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [deleteReview, setdeleteReview] = useState({});
  const [singleReview, setSingleReview] = useState({});

  const [allReviews, setAllReviews] = useState([]);
  const [loadUpdateModal, setLoadUpdateModal] = useState(false);

  React.useEffect(() => {
    getAllReviews();
  }, []);

  const getReviewDetail = (itemId) => {
    setIsLoading(true);
    apiGetAuth(
      ENDPOINTS.SingleReview + itemId,
      (res) => {
        setSingleReview(res.review)
        setUpdateModal(true);
        setLoadUpdateModal(true);
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  function getAllReviews() {
    apiGetAuth(
      ENDPOINTS.AllReviews,
      (res) => {
        setAllReviews(res.reviews);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const deleteReviewEvent = (item) => {
    console.log(item);
    setDeleteModal(true);
    setdeleteReview(item);
  }

  return (
    <>
      <Header />
      {isLoading && (
        <Loader />
      )}

      <CreateReviews showModal={showCreateModal} cloaseModal={() => setCreateModal(false)} reLoadData={() => getAllReviews()} />
      {loadUpdateModal && (
        <UpdateReviews showModalUpdate={showUpdateModal} reLoadData={() => getAllReviews()} cloaseModal={() => setUpdateModal(false)} reviewDetail={singleReview} />
      )}
      <DeleteReview showDeleteModalPro={showDeleteModal} reLoadData={() => getAllReviews()} closeDeleteModalPro={() => setDeleteModal(false)} deleteReviewPro={deleteReview} />

      <Container className="mt--7" fluid>
        <Row>
          <Col md={12} className="text-right mb-3">
            <button onClick={() => setCreateModal(true)} className="add-btn">Add</button>
          </Col>
        </Row>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Reviews tables</h3>
              </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Client Name</th>
                    <th>Client Review</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {allReviews.length && allReviews.map((item,index) =>
                    <tr key={index}>
                      <td>{item.reviewClientName}</td>
                      <td>{item.reviewClientText}</td>
                      <td><button className="edit-btn" onClick={() => getReviewDetail(item.id)}>Edit</button></td>
                      <td><button className="delete-btn" onClick={() => deleteReviewEvent(item)}>Delete</button></td>
                    </tr>
                  )}

                </tbody>

               </Table>

            </Card>
          </div>
        </Row>
      </Container>

    </>
  )
}

export default Reviews
