import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Notificationpopup from "./../../components/Notificationpopup";
import { apiPost } from "./../../auth/APIRequests";
import { ENDPOINTS } from "./../../auth/EndPoints";
import {
  CheckImageExtension,
  CheckAudioExtension,
} from "./../../auth/DateConverions";

// import { isImage } from './../../auth/DateConverions';

const CreateTrack = (props) => {
  const [showSnack, setSnack] = useState(false);
  const [snackColor, setSnackColor] = useState("bg-primary");
  const [snackMsg, setSnackMsg] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    apiPost(
      ENDPOINTS.AddTracks,
      data,
      (res) => {
        setSnack(true);
        setSnackColor("bg-success");
        setSnackMsg("Add Reviews Successfully!");
        props.cloaseModal();
        props.reLoadData();
      },
      (error) => {
        setSnack(true);
        setSnackColor("bg-danger");
        setSnackMsg("Not Added Reviews");
        console.log(error);
        props.cloaseModal();
      }
    );
  };
  const checkFilesAcceptImg = (e) => {
    const fileStatus = CheckImageExtension(e.target.files[0]);
    if (!fileStatus) {
      document.getElementById("img-input").value = null;
      setSnack(true);
      setSnackColor("bg-danger");
      setSnackMsg("Only Accept png , jpg , svg  files");
    }
  };

  const checkFilesAcceptAudio = (e) => {
    const fileStatus = CheckAudioExtension(e.target.files[0]);
    if (!fileStatus) {
      document.getElementById("audio-input").value = null;
      setSnack(true);
      setSnackColor("bg-danger");
      setSnackMsg("Only Accept mp3  files");
    }
  };

  return (
    <>
      <Modal show={props.showModal} backdrop="static">
        <Modal.Body closeButton>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Track Name</Form.Label>
              <Form.Control
                required
                type="text"
                name="trackName"
                placeholder="Class Title"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Track Price</Form.Label>
              <Form.Control
                required
                name="trackPrice"
                type="number"
                placeholder="Track Price"
              />
            </Form.Group>

            <Form.Group className="mb-3"   >
              <Form.Label>Chose Audio</Form.Label>
              <Form.Control
                required
                type="file"
                name="audiofile"
                id="audio-input"
                onChange={(e) => checkFilesAcceptAudio(e)}
                placeholder="Client Review"
              />
            </Form.Group>
            <div className="text-right">
              <Button
                variant="info"
                type="submit"
                onClick={() => props.cloaseModal()}
              >
                Close
              </Button>
              <Button variant="primary" type="submit">
                Add Track
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Notificationpopup
        closeSnackPro={() => setSnack(false)}
        showSnkPro={showSnack}
        bgColorPro={snackColor}
        snackMsgPro={snackMsg}
      />
    </>
  );
};

export default CreateTrack;
